<template>
  <div>
    <img src="../../../assets/bg-login.jpg" alt="">
  </div>
</template>
<script>
export default {
}
</script>
<style scoped>
div img{
  width: 300px;
  height: 280px;
}
</style>
