<template>
  <div
    :class="[
      'login-wrapper',
      ['', 'login-form-right', 'login-form-left'][direction]
    ]"
  >
    <div class="login-form ud-bg-white" v-if="!forgetShow">
      <h4>{{ $t('login.title') }}</h4>
      <div class="head">
        <a v-show="pswShow" :class="loginType == 0 || loginType == 1 ? 'nav' : ''" @click="changeLoginType(0)"
          >账号登录</a
        >
        <a v-show="weixinShow" :class="loginType == 2 ? 'nav' : ''" @click="changeLoginType(2)"
          >微信登录</a
        >
      </div>
      <pswLogin
        v-if="loginType == 0 && pswShow"
        v-on:toForget="toForget"
        v-on:changeLoginType="changeLoginType"
      />
      <phoneLogin v-else-if="loginType == 1 && phoneShow" v-on:changeLoginType="changeLoginType"/>
      <weixinLogin v-else-if="loginType == 2 && weixinShow" />
    </div>
    <div class="forget-form" v-else>
      <forgetForm v-on:toForget="toForget" />
    </div>
    <a-layout-footer
      class="login-copyright"
      style="text-align: center; background: none"
    >
      © 2021 远丞 all rights reserved.
    </a-layout-footer>
  </div>
</template>

<script>
import pswLogin from './components/psw.vue'
import phoneLogin from './components/phone.vue'
import weixinLogin from './components/weixin.vue'
import forgetForm from './forget.vue'

import setting from '@/config/setting'
export default {
  name: 'Login',
  components: {
    pswLogin,
    phoneLogin,
    weixinLogin,
    forgetForm
  },
  data() {
    return {
      // loginTypes: ['psw', 'phone', 'weixin'],
      loginTypes: ['psw'],
      loginType: 0,
      pswShow: false,
      phoneShow: false,
      weixinShow: false,
      forgetShow: false,
      // 登录框方向, 0居中, 1居右, 2居左
      direction: 0
    }
  },
  created() {
    if (this.loginTypes.indexOf('psw') !== -1) {
      this.pswShow = true
    } else {
      this.pswShow = false
    }

    if (this.loginTypes.indexOf('phone') !== -1) {
      this.phoneShow = true
    } else {
      this.phoneShow = false
    }

    if (this.loginTypes.indexOf('weixin') !== -1) {
      this.weixinShow = true
    } else {
      this.weixinShow = false
    }

    if (this.loginTypes[0].indexOf('psw') !== -1) {
      this.loginType = 0
    } else if (this.loginTypes[0].indexOf('phone') !== -1) {
      this.loginType = 1
    } else if (this.loginTypes[0].indexOf('weixin') !== -1) {
      this.loginType = 2
    }
  },
  computed: {
    // 当前语言
    languages() {
      return [this.$i18n.locale]
    }
  },
  mounted() {
    if (setting.getToken()) {
      return this.goHome()
    }
  },
  methods: {
    changeLoginType(val) {
      this.loginType = val
    },
    /* 切换语言 */
    changeLanguage({ key }) {
      this.$i18n.locale = key
      localStorage.setItem('i18n-lang', key)
      this.$refs.form.clearValidate()
    },
    toForget(obj) {
      if (obj === 0) {
        this.forgetShow = true
      } else {
        this.forgetShow = false
      }
    },
    /* 跳转到首页 */
    goHome() {
      const query = this.$route.query
      const path = query && query.from ? query.from : '/'
      this.$router.push(path).catch(() => {})
    }
  }
}
</script>
<style scoped>
/* 背景 */
.login-wrapper {
  padding: 48px 16px 0 16px;
  position: relative;
  box-sizing: border-box;
  background-image: url('~@/assets/bg-login.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.login-wrapper:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
}

/* 卡片 */
.login-form {
  width: 400px;
  height: 450px;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 28px;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  position: relative;
  z-index: 2;
}

.login-form h4 {
  padding: 22px 0;
  text-align: center;
}
.head {
  display: flex;
  position: relative;
  margin-bottom: 20px;
  border-bottom: 1px solid #e9e9e9;
}
.head a {
  position: relative;
  padding: 5px 20px;
  line-height: 30px;
  bottom: -1px;
  color: #000;
}
.head .nav {
  border: 1px solid #e9e9e9;
  background: #fff;
  color: #1890ff;
  border-bottom: none;
}

/* 第三方登录图标 */
.login-oauth-icon {
  color: #fff;
  padding: 5px;
  /* margin: 0 12px; */
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
}

/* 底部版权 */
.login-copyright {
  color: #eee;
  text-align: center;
  position: relative;
  z-index: 1;
}

/* 卡片 */
.forget-form {
  position: relative;
  z-index: 2;
}

/* 响应式 */
@media screen and (min-height: 640px) {
  .login-wrapper {
    padding-top: 0;
  }

  .login-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -230px;
  }

  .forget-form {
     position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -230px;
  }

  .login-copyright {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
