import request from '@/utils/request'

// api地址
const api = {
  login: '/app/portal/login',
  smsCaptcha: '/app/portal/sms/captcha',
  loginByPhoneSMS: '/app/portal/login/mpweixin',
  captcha: '/app/portal/captcha'
}

/**
 * 用户登录
 * @data data 参数
 * @returns {Promise<Array>}
 */
export function login(data) {
  return new Promise((resolve, reject) => {
    request.post(api.login, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 发送短信验证码
 * @data data 参数
 * @returns {Promise<Array>}
 */
export function smsCaptcha(data) {
  return new Promise((resolve, reject) => {
    request.post(api.smsCaptcha, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 手机号验证码登录
 * @data data 参数
 * @returns {Promise<Array>}
 */
export function loginByPhoneSMS(data) {
  return new Promise((resolve, reject) => {
    request.post(api.loginByPhoneSMS, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

export function captcha() {
  return new Promise((resolve, reject) => {
    request.get(api.captcha).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
