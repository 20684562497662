<template>
  <a-form ref="form" :model="form" :rules="rules" layout="vertical" v-if="!forgetShow">
    <a-form-item name="username">
      <a-input-group compact style="display: flex;">
        <a-input allow-clear size="large" v-model:value="form.username" :placeholder="$t('login.username')"
          style="width: 195px;" @input="handlerInput">
          <template #prefix>
            <user-outlined />
          </template>
        </a-input>
        <a-input size="large" style="width: 36px; border-left: 0; pointer-events: none; background-color: #fff"
          placeholder="@" disabled />
        <a-input allow-clear size="large" ref="inputTenat" v-model:value="form.tenant"
          :placeholder="$t('login.tenantname')" style="width: 110px;border-left: 0; " @input="removeAtLeter">
        </a-input>
      </a-input-group>
    </a-form-item>
    <a-form-item name="password">
      <a-input-password size="large" v-model:value="form.password" :placeholder="$t('login.password')"
        @focus="handleFocus">
        <template #prefix>
          <lock-outlined />
        </template>
      </a-input-password>
    </a-form-item>
    <a-form-item name="code">
      <div class="login-input-group">
        <a-input allow-clear size="large" v-model:value="form.code" :placeholder="$t('login.code')">
          <template #prefix>
            <safety-certificate-outlined />
          </template>
        </a-input>
        <a-button class="login-captcha" @click="changeCode">
          <img v-if="captcha" :src="captcha" alt="" />
        </a-button>
      </div>
    </a-form-item>
    <a-form-item>
      <a @click="toSMSLogin()" v-show="false">手机动态登录</a>
      <a @click="toForget()" class="ud-pull-right" style="line-height: 22px" v-show="false">
        {{ $t('login.forget') }}?
      </a>
    </a-form-item>
  </a-form>
  <a-form-item class="item-bottm">
    <a-button block size="large" type="primary" :loading="loading" @click="doSubmit">
      {{ loading ? $t('login.loading') : $t('login.login') }}
    </a-button>
  </a-form-item>
</template>
<script>
import * as loginApi from '@/api/sys/login'
import setting from '@/config/setting'
import {
  UserOutlined,
  LockOutlined,
  SafetyCertificateOutlined
} from '@ant-design/icons-vue'
export default {
  components: {
    UserOutlined,
    LockOutlined,
    SafetyCertificateOutlined
  },
  data() {
    return {
      forgetShow: false,
      timer: null,
      count: '',
      inputShow: true,
      showNum: false,
      getdb: 0,
      // 加载状态
      loading: false,
      // 表单数据
      form: {
        username: '',
        password: '',
        tenant: '',
        code: '',
        remember: true
      },
      // 验证码base64数据
      captcha: '',
      // 验证码内容, 实际项目去掉
      text: ''
    }
  },
  mounted() {
    this.form.tenant = setting.getTenantId() || ''
    this.changeCode()
  },
  methods: {
    toForget() {
      this.$emit('toForget', 0)
    },
    // 点击切换短信登录
    toSMSLogin() {
      this.$emit('changeLoginType', 1)
    },
    /* 提交 */
    doSubmit() {
      this.$refs.form
        .validate()
        .then(() => {
          if (this.form.code.toLowerCase() !== this.text.toLowerCase()) {
            return this.$message.error('验证码错误')
          }
          setting.setTenantId(this.form.tenant)
          this.loading = true
          loginApi.login(this.form).then((res) => {
            this.loading = false
            if (res.code === 0) {
              this.$message.success('登录成功')
              this.$store
                .dispatch('user/setToken', {
                  token: 'Bearer ' + res.access_token,
                  remember: this.form.remember
                })
                .then(() => {
                  this.goHome()
                })
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => { })
    },
    /* 跳转到首页 */
    goHome() {
      const query = this.$route.query
      const path = query && query.from ? query.from : '/'
      this.$router.push(path).catch(() => { })
    },
    /* 更换图形验证码 */
    changeCode() {
      loginApi
        .captcha()
        .then((res) => {
          if (res.code === 0) {
            this.captcha = res.data
            this.text = res.text
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    handleFocus() {

    },
    handlerInput(e) {
      const input = event.target;
      this.form.username = input.value.replace('@', '');
      if (e.data === '@') {
        this.$refs.inputTenat.focus()
      }
    },
    removeAtLeter(e) {
      const input = event.target;
      this.form.tenant = input.value.replace('@', '');
    }
  }
}
</script>
<style scoped>
.login-form-right .login-form {
  margin: 0 15% 0 auto;
}

.login-form-left .login-form {
  margin: 0 auto 0 15%;
}

/* 验证码 */
.login-input-group {
  display: flex;
  align-items: center;
}

.login-input-group :deep(.ant-input-affix-wrapper) {
  flex: 1;
}

.login-input-group .login-captcha {
  width: 102px;
  height: 40px;
  margin-left: 10px;
  padding: 0;
}

.login-input-group .login-captcha>img {
  width: 100%;
  height: 100%;
}

.item-bottm {
  padding-bottom: 10px;
}

/* 响应式 */
@media screen and (min-height: 640px) {

  .login-form-right .login-form,
  .login-form-left .login-form {
    left: auto;
    right: 15%;
    transform: translateX(0);
    margin: -230px auto auto auto;
  }

  .login-form-left .login-form {
    right: auto;
    left: 15%;
  }
}

@media screen and (max-width: 768px) {

  .login-form-right .login-form,
  .login-form-left .login-form {
    left: 50%;
    right: auto;
    margin-left: 0;
    margin-right: auto;
    transform: translateX(-50%);
  }
}

div text:hover {
  cursor: pointer;
}
</style>
