<template>
  <a-form ref="form" :model="form" :rules="rules" layout="vertical">
    <a-form-item name="phone">
      <a-input
        allow-clear
        size="large"
        v-model:value="form.phone"
        placeholder="请输入手机号码"
      >
        <template #prefix>
          <mobile-outlined />
        </template>
      </a-input>
    </a-form-item>
    <a-form-item name="code">
      <div class="login-input-group">
        <a-input
          allow-clear
          size="large"
          v-model:value="form.code"
          :placeholder="$t('login.code')"
        >
          <template #prefix>
            <safety-certificate-outlined />
          </template>
        </a-input>
        <a-button class="login-captcha" @click="changeCode">
          <img v-if="captcha" :src="captcha" alt="" />
        </a-button>
      </div>
    </a-form-item>
    <a-form-item name="smsCode" style="margin: 0">
      <a-input
        size="large"
        v-model:value="form.smsCode"
        placeholder="请输入短信验证码"
      >
        <template #prefix>
          <MessageOutlined />
        </template>
        <template #suffix>
          <div>
            <text
              @click="getnum()"
              v-if="!showNum"
              style="font-size: 10px; color: #1890ff"
              >获取验证码</text
            >
            <text v-else style="font-size: 10px; color: #999"
              >重新发送{{ count }}s</text
            >
          </div>
        </template>
      </a-input>
    </a-form-item>
    <div style="text-align: right; margin: 10px 5px 0">
      <a @click="toPwdLogin()">密码登录</a>
    </div>
    <br />
    <a-form-item class="item-bottm">
      <a-button
        block
        size="large"
        type="primary"
        :loading="loading"
        @click="doSubmit"
      >
        {{ loading ? $t('login.loading') : $t('login.login') }}
      </a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import * as loginApi from '@/api/sys/login'
import {
  MobileOutlined,
  MessageOutlined,
  SafetyCertificateOutlined
} from '@ant-design/icons-vue'
export default {
  components: {
    MobileOutlined,
    MessageOutlined,
    SafetyCertificateOutlined
  },
  data() {
    return {
      forgetShow: false,
      timer: null,
      count: '',
      showNum: false,
      // 加载状态
      loading: false,
      // 表单数据
      form: {
        smsCode: '',
        code: ''
      },
      // 验证码base64数据
      captcha: '',
      // 验证码内容, 实际项目去掉
      text: ''
    }
  },
  mounted() {
    this.changeCode()
  },
  methods: {
    toForget() {
      this.$emit('loginForget', 0)
    },
    // 点击密码登录
    toPwdLogin() {
      this.$emit('changeLoginType', 0)
    },
    // 获取验证码倒计时
    getnum() {
      this.showNum = true
      this.count = 60
      this.loading = true
      const data = {
        phone: this.form.phone
      }
      loginApi.smsCaptcha(data).then((res) => {
        this.loading = false
        if (res.code === 0) {
          this.onTimer()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    onTimer() {
      this.timer = setInterval(() => {
        this.count--
        if (this.count < 0) {
          clearInterval(this.timer)
          this.showNum = false
        }
      }, 1000)
    },
    /* 提交 */
    doSubmit() {
      this.$refs.form
        .validate()
        .then(() => {
          if (this.form.code.toLowerCase() !== this.text) {
            return this.$message.error('验证码错误')
          }
          this.loading = true
          loginApi.loginByPhoneSMS(this.form).then((res) => {
            this.loading = false
            if (res.code === 0) {
              this.$message.success('登录成功')
              this.$store
                .dispatch('user/setToken', {
                  token: 'Bearer ' + res.access_token,
                  remember: this.form.remember
                })
                .then(() => {
                  this.goHome()
                })
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {})
    },
    /* 跳转到首页 */
    goHome() {
      const query = this.$route.query
      const path = query && query.from ? query.from : '/'
      this.$router.push(path).catch(() => {})
    },
    /* 更换图形验证码 */
    changeCode() {
      // 这里演示的验证码是后端返回base64格式的形式, 如果后端地址直接是图片请参考忘记密码页面
      loginApi
        .captcha()
        .then((res) => {
          if (res.code === 0) {
            this.captcha = res.data
            // 实际项目后端一般会返回验证码的key而不是直接返回验证码的内容, 登录用key去验证, 你可以根据自己后端接口修改
            this.text = res.text
            // 自动回填验证码, 实际项目去掉这个
            this.form.code = res.text
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>
<style scoped>
.login-form-right .login-form {
  margin: 0 15% 0 auto;
}

.login-form-left .login-form {
  margin: 0 auto 0 15%;
}

/* 验证码 */
.login-input-group {
  display: flex;
  align-items: center;
}

.login-input-group :deep(.ant-input-affix-wrapper) {
  flex: 1;
}

.login-input-group .login-captcha {
  width: 102px;
  height: 40px;
  margin-left: 10px;
  padding: 0;
}

.login-input-group .login-captcha > img {
  width: 100%;
  height: 100%;
}

.item-bottm {
  padding-bottom: 10px;
}

/* 响应式 */
@media screen and (min-height: 640px) {
  .login-form-right .login-form,
  .login-form-left .login-form {
    left: auto;
    right: 15%;
    transform: translateX(0);
    margin: -230px auto auto auto;
  }

  .login-form-left .login-form {
    right: auto;
    left: 15%;
  }
}

@media screen and (max-width: 768px) {
  .login-form-right .login-form,
  .login-form-left .login-form {
    left: 50%;
    right: auto;
    margin-left: 0;
    margin-right: auto;
    transform: translateX(-50%);
  }
}
div text:hover {
  cursor: pointer;
}
</style>
